import { Component } from "react"
import React from "react"
import { Link } from "gatsby"
import gif from "../images/spktr.gif" 
import Layout from "../components/layout"
import Loader from "../components/loader"
import SEO from "../components/seo"
import CookieConsent, { Cookies } from "react-cookie-consent"
import spktrRetina from "../images/spktr-retina.png"
import arrowDown from "../images/arrow-down.png"
import "../components/font.css"
import "../components/glitch.css"
import "../components/layout.css"
import ReactFullpage from '@fullpage/react-fullpage'
import Jump from 'react-reveal/Jump'


export default class IndexPage extends Component {

  state = { loading: true }

  acceptCookie() {
    Cookies.set("SPKTRFRCookie", "accepted");
  }

  componentDidMount() {
    setTimeout(() => this.setState({ loading: false }), 2900);
  }

  render() {
    if (this.state.loading) return <Loader />

    return (
      <React.Fragment>
        <SEO title="SPKTR, cabinet de communicants" description="SPKTR est un cabinet de communicants, mi cabinet de conseil / mi agence de communication ; fondé pour répondre aux enjeux et tendances actuelles comme futures de ses clients." />

        <ReactFullpage
          licenseKey='777ECB5A-EEFD4993-980761C9-246A5E21' 
          render={({ state, fullpageApi }) => {
            return (
              <ReactFullpage.Wrapper>
                <Layout header={false}>
                  <img className="gif-logo" src={gif} alt="" />
                  <Jump>
                    <img onClick={() => fullpageApi.moveSectionDown()} className="arrow-down" src={arrowDown} alt="" />
                  </Jump>
                </Layout>

                <Layout goTopFunc={fullpageApi}>
                  <div className="organizer">
                    <p className="main-text content__text"><span className="green-text">Vous</span> ne trouverez rien ici.</p>
                    <p className="main-text spe">
                      <div className="content__text">
                        <img className="img-colors" src={spktrRetina} alt="" />
                        ( Ou peut-être <span className="blue-text">exactement</span> ce qu'il vous faut ! )
                      </div>
                    </p>
                  </div>
                </Layout>

                <Layout goTopFunc={fullpageApi}>
                  <div className="organizer">
                    <div>
                      <p className="main-text content__text">Nous préférons oeuvrer dans l'<span className="red-text">ombre</span> pour mieux vous <span className="blue-text">éclairer</span>.</p>
                      <img className="img-colors" src={spktrRetina} alt="" />
                    </div>
                  </div>
                </Layout>

                <Layout goTopFunc={fullpageApi}>
                  <div className="organizer">
                    <p className="main-text content__text">Nous prônons une communication <span className="red-text">directe</span>, <span className="green-text">efficace</span>, sans <span className="blue-text">fioriture</span>.</p>
                    <img className="img-colors" src={spktrRetina} alt="" />
                  </div>
                </Layout>

                <Layout goTopFunc={fullpageApi}>
                  <div className="organizer">
                    <p className="main-text content__text">Ça vous parle ?</p>
                    <img className="img-colors" src={spktrRetina} alt="" />
                  </div>
                </Layout>

                <Layout goTopFunc={fullpageApi}>
                  <div style={{position: 'relative', width: '100%', minHeight: '100vh'}}>
                    <img src={spktrRetina} alt="" className="contact-img" />
                    <div className="box">
                      <a className="main-text glitch-contact" href="mailto:contact@spktr.fr">contact</a>
                    </div>
                  </div>
                  <Link to="/mentions-legales/" className="mentions-link">Mentions légales</Link>
                </Layout>
              </ReactFullpage.Wrapper>
            );
          }}
        />

        <CookieConsent
          location="bottom"
          buttonText="J'ai compris"
          cookieName="SPKTRFRCookie"
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={365}
          onAccept={() => this.acceptCookie()}
          acceptOnScroll={true}
        >
          En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de Cookies ou autres traceurs pour vous par exemple, réaliser des statistiques de visites.
        </CookieConsent>
      </React.Fragment>
    )
  }
}
