import React from "react"
import Header from "./header"

const Layout = ({ header = true, children, goTopFunc }) => (
  <section className="main-section section">
    <main className="demo-1 loading">
        <div className="glitch">
          <div className="glitch__img"></div>
          <div className="glitch__img"></div>
          <div className="glitch__img"></div>
          <div className="glitch__img"></div>
          <div className="glitch__img"></div>

          {header && <Header goTop={goTopFunc} />}
          {children}
      </div>
    </main>
  </section>
)

export default Layout
