import React from "react"
import { Component } from "react"
import SEO from "../components/seo"

export default class Loader extends Component {

  componentDidMount() {
    const elem = document.getElementById("myBar");
    let width = 0;
    const id = setInterval(frame, 28);

    function frame() {
      if (width >= 100) {
        clearInterval(id);
      } else {
        width++; 
        elem.innerHTML = width * 1  + '%';
      }
    }
  }
  
  render() {
    return (
      <div className="loader-container">
        <SEO title="SPKTR, cabinet de communicants" description="SPKTR est un cabinet de communicants, mi cabinet de conseil / mi agence de communication ; fondé pour répondre aux enjeux et tendances actuelles comme futures de ses clients." />
        <div className="m-loader is-loaded">
          <div className="m-loader__wrapper">
            <div className="m-loader__line">
              <div></div>
            </div> 
          </div>
        </div>
        <div id="myBar">0%</div>
      </div>
    )
  }
}
